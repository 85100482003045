<template>
  <div class="bigboxs1">
    <div class="font1">{{ list.productName }}</div>
    <!-- 广告图详情 -->
    <div class="imgBox">
      <img class="imgs" :src="list.imgs" alt="" />
    </div>
    <div v-html="list.productContent"></div>
  </div>
</template>

<script>
import common from "../../api/common.js";
export default {
  data() {
    return {
      list: {},
    };
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.getList();
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      common.productById(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.bigboxs1 {
  width: 100%;
  padding: 40px;
  background-color: #ffffff;
}
.font1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 16px;
  text-align: center;
}
.imgBox {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.imgs {
  width: 96%;
  display: block;
}
</style>